import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet en forma globosa, irregular de fins a 6 cm de diàmetre. La superfície externa està formada per plaques piramidals, juntes i de color quasi negre, en canvi la massa interna és de color blanquinós, recorreguda per una mena de venes blanques. Fa una olor suau a avellana, sobretot quan és madur. Les espores són el·líptiques o un poc esfèriques, de 25-50 x 17-40 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      